/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.30/esri/copyright.txt for details.
*/
import { earth as n, mars as e, moon as r } from "./support/Ellipsoid.js";
import { isValid as t, isWKIDFromMars as i, isWKIDFromMoon as c, equals as o } from "./support/spatialReferenceUtils.js";
function S(n) {
  return {
    wkt: `GEOCCS["Spherical geocentric",\n    DATUM["Not specified",\n      SPHEROID["Sphere",${n.radius},0]],\n    PRIMEM["Greenwich",0.0,\n      AUTHORITY["EPSG","8901"]],\n    UNIT["m",1.0],\n    AXIS["Geocentric X",OTHER],\n    AXIS["Geocentric Y",EAST],\n    AXIS["Geocentric Z",NORTH]\n  ]`
  };
}
const T = S(n),
  G = S(e),
  p = S(r),
  u = {
    wkt: `GEOCCS["WGS 84",\n  DATUM["WGS_1984",\n    SPHEROID["WGS 84",${n.radius},298.257223563,\n      AUTHORITY["EPSG","7030"]],\n    AUTHORITY["EPSG","6326"]],\n  PRIMEM["Greenwich",0,\n    AUTHORITY["EPSG","8901"]],\n  UNIT["m",1.0,\n    AUTHORITY["EPSG","9001"]],\n  AXIS["Geocentric X",OTHER],\n  AXIS["Geocentric Y",OTHER],\n  AXIS["Geocentric Z",NORTH],\n  AUTHORITY["EPSG","4978"]\n]`
  },
  I = G.wkt.toUpperCase(),
  E = p.wkt.toUpperCase();
function s(o) {
  if (null != o && t(o)) {
    const n = o.wkid,
      t = o.wkt2 ?? o.wkt;
    if (i(n)) return e;
    const S = t?.toUpperCase();
    if (t && S === I) return e;
    if (c(n) || S && S === E) return r;
  }
  return n;
}
function R(t) {
  return i(t) ? e : c(t) ? r : n;
}
function A(n) {
  return o(n, T);
}
function O(n) {
  return A(n) || o(n, G) || o(n, p);
}
export { T as SphericalECEFSpatialReferenceLike, G as SphericalPCPFMarsLike, p as SphericalPCPFMoonLike, u as WGS84ECEFSpatialReferenceLike, s as getReferenceEllipsoid, R as getReferenceEllipsoidFromWKID, A as isSphericalECEF, O as isSphericalPCPF };