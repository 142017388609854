/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.30/esri/copyright.txt for details.
*/
import "./has.js";
import { strict as e } from "./jsonMap.js";
import { getReferenceEllipsoid as s } from "../geometry/ellipsoidUtils.js";
import { earth as t, mars as r, moon as i } from "../geometry/support/Ellipsoid.js";
import { isEarth as n, isWKIDFromMars as a, isWKIDFromMoon as u, isGeographic as c } from "../geometry/support/spatialReferenceUtils.js";
import l from "../geometry/support/WKIDUnitConversion.js";
const o = 39.37,
  m = t.radius * Math.PI / 200,
  f = /(?:LENGTH)?UNIT\[([^\]]+)]]$/i,
  d = l,
  U = /UNIT\[([^\]]+)]/i,
  q = new Set([4261, 4305, 4807, 4810, 4811, 4812, 4816, 4819, 4821, 4901, 4902, 37225, 104139, 104140]),
  h = e()({
    meter: "meters",
    foot: "feet",
    foot_us: "us-feet",
    foot_clarke: "clarke-feet",
    yard_clarke: "clarke-yards",
    link_clarke: "clarke-links",
    yard_sears: "sears-yards",
    foot_sears: "sears-feet",
    chain_sears: "sears-chains",
    chain_benoit_1895_b: "benoit-1895-b-chains",
    yard_indian: "indian-yards",
    yard_indian_1937: "indian-1937-yards",
    foot_gold_coast: "gold-coast-feet",
    chain_sears_1922_truncated: "sears-1922-truncated-chains",
    "50_kilometers": "50-kilometers",
    "150_kilometers": "150-kilometers"
  }),
  B = e => e * e,
  k = e => e * e * e,
  b = {
    length: {
      baseUnit: "meters",
      units: {
        millimeters: {
          inBaseUnits: .001
        },
        centimeters: {
          inBaseUnits: .01
        },
        decimeters: {
          inBaseUnits: .1
        },
        meters: {
          inBaseUnits: 1
        },
        kilometers: {
          inBaseUnits: 1e3
        },
        inches: {
          inBaseUnits: .0254
        },
        feet: {
          inBaseUnits: .3048
        },
        yards: {
          inBaseUnits: .9144
        },
        miles: {
          inBaseUnits: 1609.344
        },
        "nautical-miles": {
          inBaseUnits: 1852
        },
        "us-feet": {
          inBaseUnits: 1200 / 3937
        }
      }
    },
    area: {
      baseUnit: "square-meters",
      units: {
        "square-millimeters": {
          inBaseUnits: B(.001)
        },
        "square-centimeters": {
          inBaseUnits: B(.01)
        },
        "square-decimeters": {
          inBaseUnits: B(.1)
        },
        "square-meters": {
          inBaseUnits: 1
        },
        "square-kilometers": {
          inBaseUnits: B(1e3)
        },
        "square-inches": {
          inBaseUnits: B(.0254)
        },
        "square-feet": {
          inBaseUnits: B(.3048)
        },
        "square-yards": {
          inBaseUnits: B(.9144)
        },
        "square-miles": {
          inBaseUnits: B(1609.344)
        },
        "square-us-feet": {
          inBaseUnits: B(1200 / 3937)
        },
        acres: {
          inBaseUnits: .0015625 * B(1609.344)
        },
        ares: {
          inBaseUnits: 100
        },
        hectares: {
          inBaseUnits: 1e4
        }
      }
    },
    volume: {
      baseUnit: "liters",
      units: {
        liters: {
          inBaseUnits: 1
        },
        "cubic-millimeters": {
          inBaseUnits: 1e3 * k(.001)
        },
        "cubic-centimeters": {
          inBaseUnits: 1e3 * k(.01)
        },
        "cubic-decimeters": {
          inBaseUnits: 1e3 * k(.1)
        },
        "cubic-meters": {
          inBaseUnits: 1e3
        },
        "cubic-kilometers": {
          inBaseUnits: 1e3 * k(1e3)
        },
        "cubic-inches": {
          inBaseUnits: 1e3 * k(.0254)
        },
        "cubic-feet": {
          inBaseUnits: 1e3 * k(.3048)
        },
        "cubic-yards": {
          inBaseUnits: 1e3 * k(.9144)
        },
        "cubic-miles": {
          inBaseUnits: 1e3 * k(1609.344)
        }
      }
    },
    angle: {
      baseUnit: "radians",
      units: {
        radians: {
          inBaseUnits: 1
        },
        degrees: {
          inBaseUnits: Math.PI / 180
        }
      }
    }
  },
  p = (() => {
    const e = {};
    for (const s in b) for (const t in b[s].units) e[t] = s;
    return e;
  })();
function y(e, s, t) {
  return e * b[t].units[s].inBaseUnits;
}
function _(e, s, t) {
  return e / b[t].units[s].inBaseUnits;
}
const w = ["metric", "imperial", "square-inches", "square-feet", "square-yards", "square-miles", "square-us-feet", "square-meters", "square-kilometers", "acres", "ares", "hectares"],
  M = ["metric", "imperial", "inches", "feet", "yards", "miles", "nautical-miles", "us-feet", "meters", "kilometers"],
  g = new Map([["meters", "square-meters"], ["feet", "square-feet"], ["us-feet", "square-us-feet"]]);
function S(e) {
  return "imperial" === e || "metric" === e;
}
function D(e) {
  const s = p[e];
  if (!s) throw new Error("unknown type");
  return s;
}
function C(e) {
  return b[e].baseUnit;
}
function P(e) {
  return C(D(e));
}
function I(e, s = null) {
  return s = s || D(e), b[s].baseUnit === e;
}
function j(e, s, t) {
  if (s === t) return e;
  const r = D(s);
  if (r !== D(t)) throw new Error("incompatible units");
  const i = I(s, r) ? e : y(e, s, r);
  return I(t, r) ? i : _(i, t, r);
}
function E(e) {
  switch (e) {
    case "metric":
      return "meters";
    case "imperial":
      return "feet";
    default:
      return e;
  }
}
function R(e) {
  return E(e);
}
function x(e) {
  switch (e) {
    case "metric":
      return "square-meters";
    case "imperial":
      return "square-feet";
    default:
      return e;
  }
}
function N(e, s, t) {
  switch (t) {
    case "metric":
      return v(e, s);
    case "imperial":
      return J(e, s);
    default:
      return t;
  }
}
function O(e, s, t) {
  switch (t) {
    case "metric":
      return F(e, s);
    case "imperial":
      return T(e, s);
    default:
      return t;
  }
}
function v(e, s) {
  const t = j(e, s, "meters");
  return Math.abs(t) < 3e3 ? "meters" : "kilometers";
}
function F(e, s) {
  const t = j(e, s, "meters");
  return Math.abs(t) < 1e5 ? "meters" : "kilometers";
}
function J(e, s) {
  const t = j(e, s, "feet");
  return Math.abs(t) < 1e3 ? "feet" : "miles";
}
function T(e, s) {
  const t = j(e, s, "feet");
  return Math.abs(t) < 1e5 ? "feet" : "miles";
}
function K(e, s) {
  const t = j(e, s, "square-meters");
  return Math.abs(t) < 3e6 ? "square-meters" : "square-kilometers";
}
function A(e, s) {
  const t = j(e, s, "square-feet");
  return Math.abs(t) < 1e6 ? "square-feet" : "square-miles";
}
function G(e, s, t) {
  return j(e, s, "meters") / (t * Math.PI / 180);
}
function H(e) {
  return h.fromJSON(e.toLowerCase()) || null;
}
function L(e) {
  if (null != e && !n(e)) return 1;
  const s = W(e);
  return s > 1e5 ? 1 : s;
}
function Y(e) {
  return W(e) >= s(e).metersPerDegree ? "meters" : X(e);
}
function W(e, s = t.metersPerDegree) {
  return $(e, !0) ?? s;
}
function $(e, s = !1) {
  const t = e?.wkid ?? null,
    n = e?.wkt2 ?? e?.wkt ?? null;
  let c = null;
  if (t) {
    if (a(t)) return r.metersPerDegree;
    if (u(t)) return i.metersPerDegree;
    c = d.values[d[t]], !c && s && q.has(t) && (c = m);
  } else n && (re(n) ? c = Q(f.exec(n), c) : te(n) && (c = Q(U.exec(n), c)));
  return c;
}
function z(e) {
  return c(e) ? 1 : W(e);
}
function Q(e, s) {
  return e?.[1] ? V(e[1]) : s;
}
function V(e) {
  return parseFloat(e.split(",")[1]);
}
function X(e) {
  const s = e?.wkid ?? null,
    t = e?.wkt2 ?? e?.wkt ?? null;
  let r = null;
  if (s) r = d.units[d[s]];else if (t) {
    const e = re(t) ? f : te(t) ? U : null;
    if (e) {
      const s = e.exec(t);
      s?.[1] && (r = ne(s[1]));
    }
  }
  return null != r ? H(r) : null;
}
function Z(e) {
  const s = X(e);
  return null != s && M.includes(s) ? s : null;
}
function ee(e) {
  const s = Y(e);
  return null != s && M.includes(s) ? s : null;
}
function se(e) {
  const s = X(e);
  return null == s ? null : g.get(s);
}
function te(e) {
  return /^GEOCCS/i.test(e);
}
function re(e) {
  return /^\s*(?:PROJCS|PROJCRS|PROJECTEDCRS)/i.test(e);
}
const ie = 1e-7;
function ne(e) {
  const s = /[\\"']{1}([^\\"']+)/.exec(e);
  let t = s?.[1];
  if (!t || !d.units.includes(t)) {
    const s = V(e);
    t = null;
    const r = d.values;
    for (let e = 0; e < r.length; ++e) if (Math.abs(s - r[e]) < ie) {
      t = d.units[e];
      break;
    }
  }
  return t;
}
function ae(e) {
  const s = X(e);
  if (null == s) return null;
  switch (s) {
    case "feet":
    case "us-feet":
    case "clarke-feet":
    case "clarke-yards":
    case "clarke-links":
    case "sears-yards":
    case "sears-feet":
    case "sears-chains":
    case "benoit-1895-b-chains":
    case "indian-yards":
    case "indian-1937-yards":
    case "gold-coast-feet":
    case "sears-1922-truncated-chains":
      return "imperial";
    case "50-kilometers":
    case "150-kilometers":
    case "meters":
      return "metric";
  }
  return null;
}
const ue = {
    esriAcres: "acres",
    esriAres: "ares",
    esriHectares: "hectares",
    esriSquareCentimeters: "square-centimeters",
    esriSquareDecimeters: "square-decimeters",
    esriSquareFeet: "square-feet",
    esriSquareInches: "square-inches",
    esriSquareKilometers: "square-kilometers",
    esriSquareMeters: "square-meters",
    esriSquareMiles: "square-miles",
    esriSquareMillimeters: "square-millimeters",
    esriSquareUsFeet: "square-us-feet",
    esriSquareYards: "square-yards"
  },
  ce = {
    esriCentimeters: "centimeters",
    esriDecimeters: "decimeters",
    esriFeet: "feet",
    esriInches: "inches",
    esriKilometers: "kilometers",
    esriMeters: "meters",
    esriMiles: "miles",
    esriMillimeters: "millimeters",
    esriNauticalMiles: "nautical-miles",
    esriYards: "yards"
  },
  le = {
    esriDUDecimalDegrees: "degrees",
    esriDURadians: "radians"
  },
  oe = e()(ue),
  me = e()(ce),
  fe = e()(le);
export { A as adaptiveImperialAreaUnit, J as adaptiveImperialLengthUnit, T as adaptiveImperialVerticalLengthUnit, N as adaptiveLengthUnit, K as adaptiveMetricAreaUnit, v as adaptiveMetricLengthUnit, F as adaptiveMetricVerticalLengthUnit, O as adaptiveVerticalLengthUnit, fe as angleUnitsJSONMap, se as areaUnitFromSpatialReference, oe as areaUnitsJSONMap, P as baseUnitForUnit, C as baseUnitForUnitType, j as convertUnit, x as defaultAreaUnit, E as defaultLengthUnit, R as defaultVerticalLengthUnit, ae as getDefaultUnitSystem, z as getMetersPerCartesianUnitForSR, $ as getMetersPerUnit, W as getMetersPerUnitForSR, L as getMetersPerVerticalUnitForSR, X as getUnitString, Y as getVerticalUnitStringForSR, o as inchesPerMeter, I as isBaseUnit, S as isMeasurementSystem, re as isProjectedWKT, G as lengthToDegrees, Z as lengthUnitFromSpatialReference, me as lengthUnitsJSONMap, w as measurementAreaUnits, M as measurementLengthUnits, H as unitFromRESTJSON, D as unitType, ee as verticalLengthUnitFromSpatialReference };