/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.30/esri/copyright.txt for details.
*/
let o;
const r = {
  values: [1, .3048, .3048006096012192, .3047972654, .9143917962, .201166195164, .9143984146160287, .3047994715386762, 20.11676512155263, 20.11678249437587, .9143985307444408, .91439523, .3047997101815088, 20.1168, 20.116756, 5e4, 15e4],
  units: ["Meter", "Foot", "Foot_US", "Foot_Clarke", "Yard_Clarke", "Link_Clarke", "Yard_Sears", "Foot_Sears", "Chain_Sears", "Chain_Benoit_1895_B", "Yard_Indian", "Yard_Indian_1937", "Foot_Gold_Coast", "Chain", "Chain_Sears_1922_Truncated", "50_Kilometers", "150_Kilometers"],
  2066: 5,
  2136: 12,
  2155: 2,
  2157: 0,
  2158: 0,
  2159: 12,
  2160: 12,
  2204: 2,
  2219: 0,
  2220: 0,
  2254: 2,
  2255: 2,
  2256: 1,
  2265: 1,
  2266: 1,
  2267: 2,
  2268: 2,
  2269: 1,
  2270: 1,
  2271: 2,
  2272: 2,
  2273: 1,
  2294: 0,
  2295: 0,
  2314: 3,
  2899: 2,
  2900: 2,
  2901: 1,
  2909: 1,
  2910: 1,
  2911: 2,
  2912: 2,
  2913: 1,
  2914: 1,
  2992: 1,
  2993: 0,
  2994: 1,
  3080: 1,
  3089: 2,
  3090: 0,
  3091: 2,
  3102: 2,
  3141: 0,
  3142: 0,
  3167: 14,
  3359: 2,
  3360: 0,
  3361: 1,
  3362: 0,
  3363: 2,
  3364: 0,
  3365: 2,
  3366: 3,
  3404: 2,
  3405: 0,
  3406: 0,
  3407: 3,
  3439: 0,
  3440: 0,
  3479: 1,
  3480: 0,
  3481: 1,
  3482: 0,
  3483: 1,
  3484: 0,
  3485: 2,
  3486: 0,
  3487: 2,
  3488: 0,
  3489: 0,
  3490: 2,
  3491: 0,
  3492: 2,
  3493: 0,
  3494: 2,
  3495: 0,
  3496: 2,
  3497: 0,
  3498: 2,
  3499: 0,
  3500: 2,
  3501: 0,
  3502: 2,
  3503: 0,
  3504: 2,
  3505: 0,
  3506: 2,
  3507: 0,
  3508: 2,
  3509: 0,
  3510: 2,
  3511: 0,
  3512: 2,
  3513: 0,
  3514: 0,
  3515: 2,
  3516: 0,
  3517: 2,
  3518: 0,
  3519: 2,
  3520: 0,
  3521: 2,
  3522: 0,
  3523: 2,
  3524: 0,
  3525: 2,
  3526: 0,
  3527: 2,
  3528: 0,
  3529: 2,
  3530: 0,
  3531: 2,
  3532: 0,
  3533: 2,
  3534: 0,
  3535: 2,
  3536: 0,
  3537: 2,
  3538: 0,
  3539: 2,
  3540: 0,
  3541: 2,
  3542: 0,
  3543: 2,
  3544: 0,
  3545: 2,
  3546: 0,
  3547: 2,
  3548: 0,
  3549: 2,
  3550: 0,
  3551: 2,
  3552: 0,
  3553: 2,
  3582: 2,
  3583: 0,
  3584: 2,
  3585: 0,
  3586: 2,
  3587: 0,
  3588: 1,
  3589: 0,
  3590: 1,
  3591: 0,
  3592: 0,
  3593: 1,
  3598: 2,
  3599: 0,
  3600: 2,
  3605: 1,
  3606: 0,
  3607: 0,
  3608: 2,
  3609: 0,
  3610: 2,
  3611: 0,
  3612: 2,
  3613: 0,
  3614: 2,
  3615: 0,
  3616: 2,
  3617: 0,
  3618: 2,
  3619: 0,
  3620: 2,
  3621: 0,
  3622: 2,
  3623: 0,
  3624: 2,
  3625: 0,
  3626: 2,
  3627: 0,
  3628: 2,
  3629: 0,
  3630: 2,
  3631: 0,
  3632: 2,
  3633: 0,
  3634: 1,
  3635: 0,
  3636: 1,
  3640: 2,
  3641: 0,
  3642: 2,
  3643: 0,
  3644: 1,
  3645: 0,
  3646: 1,
  3647: 0,
  3648: 1,
  3649: 0,
  3650: 2,
  3651: 0,
  3652: 2,
  3653: 0,
  3654: 2,
  3655: 0,
  3656: 1,
  3657: 0,
  3658: 2,
  3659: 0,
  3660: 2,
  3661: 0,
  3662: 2,
  3663: 0,
  3664: 2,
  3668: 2,
  3669: 0,
  3670: 2,
  3671: 0,
  3672: 2,
  3673: 0,
  3674: 2,
  3675: 0,
  3676: 1,
  3677: 2,
  3678: 0,
  3679: 1,
  3680: 2,
  3681: 0,
  3682: 1,
  3683: 2,
  3684: 0,
  3685: 0,
  3686: 2,
  3687: 0,
  3688: 2,
  3689: 0,
  3690: 2,
  3691: 0,
  3692: 2,
  3696: 2,
  3697: 0,
  3698: 2,
  3699: 0,
  3700: 2,
  3793: 0,
  3794: 0,
  3812: 0,
  3854: 0,
  3857: 0,
  3920: 0,
  3978: 0,
  3979: 0,
  3991: 2,
  3992: 2,
  4026: 0,
  4037: 0,
  4038: 0,
  4071: 0,
  4082: 0,
  4083: 0,
  4087: 0,
  4088: 0,
  4217: 2,
  4414: 0,
  4415: 0,
  4417: 0,
  4434: 0,
  4437: 0,
  4438: 2,
  4439: 2,
  4462: 0,
  4467: 0,
  4471: 0,
  4474: 0,
  4559: 0,
  4647: 0,
  4822: 0,
  4826: 0,
  4839: 0,
  5018: 0,
  5041: 0,
  5042: 0,
  5048: 0,
  5221: 0,
  5223: 0,
  5234: 0,
  5235: 0,
  5243: 0,
  5247: 0,
  5266: 0,
  5316: 0,
  5320: 0,
  5321: 0,
  5325: 0,
  5337: 0,
  5361: 0,
  5362: 0,
  5367: 0,
  5382: 0,
  5383: 0,
  5396: 0,
  5456: 0,
  5457: 0,
  5469: 0,
  5472: 4,
  5490: 0,
  5513: 0,
  5514: 0,
  5523: 0,
  5559: 0,
  5588: 1,
  5589: 3,
  5596: 0,
  5627: 0,
  5629: 0,
  5641: 0,
  5643: 0,
  5644: 0,
  5646: 2,
  5654: 2,
  5655: 2,
  5659: 0,
  5700: 0,
  5825: 0,
  5836: 0,
  5837: 0,
  5839: 0,
  5842: 0,
  5844: 0,
  5858: 0,
  5879: 0,
  5880: 0,
  5887: 0,
  5890: 0,
  6128: 1,
  6129: 1,
  6141: 1,
  6204: 0,
  6210: 0,
  6211: 0,
  6307: 0,
  6312: 0,
  6316: 0,
  6362: 0,
  6391: 1,
  6405: 1,
  6406: 0,
  6407: 1,
  6408: 0,
  6409: 1,
  6410: 0,
  6411: 2,
  6412: 0,
  6413: 2,
  6414: 0,
  6415: 0,
  6416: 2,
  6417: 0,
  6418: 2,
  6419: 0,
  6420: 2,
  6421: 0,
  6422: 2,
  6423: 0,
  6424: 2,
  6425: 0,
  6426: 2,
  6427: 0,
  6428: 2,
  6429: 0,
  6430: 2,
  6431: 0,
  6432: 2,
  6433: 0,
  6434: 2,
  6435: 0,
  6436: 2,
  6437: 0,
  6438: 2,
  6439: 0,
  6440: 0,
  6441: 2,
  6442: 0,
  6443: 2,
  6444: 0,
  6445: 2,
  6446: 0,
  6447: 2,
  6448: 0,
  6449: 2,
  6450: 0,
  6451: 2,
  6452: 0,
  6453: 2,
  6454: 0,
  6455: 2,
  6456: 0,
  6457: 2,
  6458: 0,
  6459: 2,
  6460: 0,
  6461: 2,
  6462: 0,
  6463: 2,
  6464: 0,
  6465: 2,
  6466: 0,
  6467: 2,
  6468: 0,
  6469: 2,
  6470: 0,
  6471: 2,
  6472: 0,
  6473: 2,
  6474: 0,
  6475: 2,
  6476: 0,
  6477: 2,
  6478: 0,
  6479: 2,
  6484: 2,
  6485: 0,
  6486: 2,
  6487: 0,
  6488: 2,
  6489: 0,
  6490: 2,
  6491: 0,
  6492: 2,
  6493: 0,
  6494: 1,
  6495: 0,
  6496: 1,
  6497: 0,
  6498: 0,
  6499: 1,
  6500: 0,
  6501: 2,
  6502: 0,
  6503: 2,
  6504: 0,
  6505: 2,
  6506: 0,
  6507: 2,
  6508: 0,
  6509: 0,
  6510: 2,
  6515: 1,
  6516: 0,
  6518: 0,
  6519: 2,
  6520: 0,
  6521: 2,
  6522: 0,
  6523: 2,
  6524: 0,
  6525: 2,
  6526: 0,
  6527: 2,
  6528: 0,
  6529: 2,
  6530: 0,
  6531: 2,
  6532: 0,
  6533: 2,
  6534: 0,
  6535: 2,
  6536: 0,
  6537: 2,
  6538: 0,
  6539: 2,
  6540: 0,
  6541: 2,
  6542: 0,
  6543: 2,
  6544: 0,
  6545: 1,
  6546: 0,
  6547: 1,
  6548: 0,
  6549: 2,
  6550: 0,
  6551: 2,
  6552: 0,
  6553: 2,
  6554: 0,
  6555: 2,
  6556: 0,
  6557: 1,
  6558: 0,
  6559: 1,
  6560: 0,
  6561: 1,
  6562: 0,
  6563: 2,
  6564: 0,
  6565: 2,
  6566: 0,
  6567: 0,
  6568: 2,
  6569: 0,
  6570: 1,
  6571: 0,
  6572: 2,
  6573: 0,
  6574: 2,
  6575: 0,
  6576: 2,
  6577: 0,
  6578: 2,
  6582: 2,
  6583: 0,
  6584: 2,
  6585: 0,
  6586: 2,
  6587: 0,
  6588: 2,
  6589: 0,
  6590: 2,
  6591: 0,
  6592: 0,
  6593: 2,
  6594: 0,
  6595: 2,
  6596: 0,
  6597: 2,
  6598: 0,
  6599: 2,
  6600: 0,
  6601: 2,
  6602: 0,
  6603: 2,
  6605: 2,
  6606: 0,
  6607: 2,
  6608: 0,
  6609: 2,
  6610: 0,
  6611: 0,
  6612: 2,
  6613: 0,
  6614: 2,
  6615: 0,
  6616: 2,
  6617: 0,
  6618: 2,
  6633: 2,
  6646: 0,
  6703: 0,
  6784: 0,
  6785: 1,
  6786: 0,
  6787: 1,
  6788: 0,
  6789: 1,
  6790: 0,
  6791: 1,
  6792: 0,
  6793: 1,
  6794: 0,
  6795: 1,
  6796: 0,
  6797: 1,
  6798: 0,
  6799: 1,
  6800: 0,
  6801: 1,
  6802: 0,
  6803: 1,
  6804: 0,
  6805: 1,
  6806: 0,
  6807: 1,
  6808: 0,
  6809: 1,
  6810: 0,
  6811: 1,
  6812: 0,
  6813: 1,
  6814: 0,
  6815: 1,
  6816: 0,
  6817: 1,
  6818: 0,
  6819: 1,
  6820: 0,
  6821: 1,
  6822: 0,
  6823: 1,
  6824: 0,
  6825: 1,
  6826: 0,
  6827: 1,
  6828: 0,
  6829: 1,
  6830: 0,
  6831: 1,
  6832: 0,
  6833: 1,
  6834: 0,
  6835: 1,
  6836: 0,
  6837: 1,
  6838: 0,
  6839: 1,
  6840: 0,
  6841: 1,
  6842: 0,
  6843: 1,
  6844: 0,
  6845: 1,
  6846: 0,
  6847: 1,
  6848: 0,
  6849: 1,
  6850: 0,
  6851: 1,
  6852: 0,
  6853: 1,
  6854: 0,
  6855: 1,
  6856: 0,
  6857: 1,
  6858: 0,
  6859: 1,
  6860: 0,
  6861: 1,
  6862: 0,
  6863: 1,
  6867: 0,
  6868: 1,
  6870: 0,
  6875: 0,
  6876: 0,
  6879: 0,
  6880: 2,
  6884: 0,
  6885: 1,
  6886: 0,
  6887: 1,
  6915: 0,
  6922: 0,
  6923: 2,
  6924: 0,
  6925: 2,
  6962: 0,
  6984: 0,
  6991: 0,
  7128: 2,
  7131: 0,
  7132: 2,
  7142: 0,
  7257: 0,
  7258: 2,
  7259: 0,
  7260: 2,
  7261: 0,
  7262: 2,
  7263: 0,
  7264: 2,
  7265: 0,
  7266: 2,
  7267: 0,
  7268: 2,
  7269: 0,
  7270: 2,
  7271: 0,
  7272: 2,
  7273: 0,
  7274: 2,
  7275: 0,
  7276: 2,
  7277: 0,
  7278: 2,
  7279: 0,
  7280: 2,
  7281: 0,
  7282: 2,
  7283: 0,
  7284: 2,
  7285: 0,
  7286: 2,
  7287: 0,
  7288: 2,
  7289: 0,
  7290: 2,
  7291: 0,
  7292: 2,
  7293: 0,
  7294: 2,
  7295: 0,
  7296: 2,
  7297: 0,
  7298: 2,
  7299: 0,
  7300: 2,
  7301: 0,
  7302: 2,
  7303: 0,
  7304: 2,
  7305: 0,
  7306: 2,
  7307: 0,
  7308: 2,
  7309: 0,
  7310: 2,
  7311: 0,
  7312: 2,
  7313: 0,
  7314: 2,
  7315: 0,
  7316: 2,
  7317: 0,
  7318: 2,
  7319: 0,
  7320: 2,
  7321: 0,
  7322: 2,
  7323: 0,
  7324: 2,
  7325: 0,
  7326: 2,
  7327: 0,
  7328: 2,
  7329: 0,
  7330: 2,
  7331: 0,
  7332: 2,
  7333: 0,
  7334: 2,
  7335: 0,
  7336: 2,
  7337: 0,
  7338: 2,
  7339: 0,
  7340: 2,
  7341: 0,
  7342: 2,
  7343: 0,
  7344: 2,
  7345: 0,
  7346: 2,
  7347: 0,
  7348: 2,
  7349: 0,
  7350: 2,
  7351: 0,
  7352: 2,
  7353: 0,
  7354: 2,
  7355: 0,
  7356: 2,
  7357: 0,
  7358: 2,
  7359: 0,
  7360: 2,
  7361: 0,
  7362: 2,
  7363: 0,
  7364: 2,
  7365: 0,
  7366: 2,
  7367: 0,
  7368: 2,
  7369: 0,
  7370: 2,
  7877: 0,
  7878: 0,
  7882: 0,
  7883: 0,
  7887: 0,
  7899: 0,
  7991: 0,
  7992: 0,
  8035: 2,
  8036: 2,
  8058: 0,
  8059: 0,
  8082: 0,
  8083: 0,
  8088: 0,
  8090: 0,
  8091: 2,
  8092: 0,
  8093: 2,
  8095: 0,
  8096: 2,
  8097: 0,
  8098: 2,
  8099: 0,
  8100: 2,
  8101: 0,
  8102: 2,
  8103: 0,
  8104: 2,
  8105: 0,
  8106: 2,
  8107: 0,
  8108: 2,
  8109: 0,
  8110: 2,
  8111: 0,
  8112: 2,
  8113: 0,
  8114: 2,
  8115: 0,
  8116: 2,
  8117: 0,
  8118: 2,
  8119: 0,
  8120: 2,
  8121: 0,
  8122: 2,
  8123: 0,
  8124: 2,
  8125: 0,
  8126: 2,
  8127: 0,
  8128: 2,
  8129: 0,
  8130: 2,
  8131: 0,
  8132: 2,
  8133: 0,
  8134: 2,
  8135: 0,
  8136: 2,
  8137: 0,
  8138: 2,
  8139: 0,
  8140: 2,
  8141: 0,
  8142: 2,
  8143: 0,
  8144: 2,
  8145: 0,
  8146: 2,
  8147: 0,
  8148: 2,
  8149: 0,
  8150: 2,
  8151: 0,
  8152: 2,
  8153: 0,
  8154: 2,
  8155: 0,
  8156: 2,
  8157: 0,
  8158: 2,
  8159: 0,
  8160: 2,
  8161: 0,
  8162: 2,
  8163: 0,
  8164: 2,
  8165: 0,
  8166: 2,
  8167: 0,
  8168: 2,
  8169: 0,
  8170: 2,
  8171: 0,
  8172: 2,
  8173: 0,
  8177: 2,
  8179: 0,
  8180: 2,
  8181: 0,
  8182: 2,
  8184: 0,
  8185: 2,
  8187: 0,
  8189: 2,
  8191: 0,
  8193: 2,
  8196: 0,
  8197: 2,
  8198: 0,
  8200: 2,
  8201: 0,
  8202: 2,
  8203: 0,
  8204: 2,
  8205: 0,
  8206: 2,
  8207: 0,
  8208: 2,
  8209: 0,
  8210: 2,
  8212: 0,
  8213: 2,
  8214: 0,
  8216: 2,
  8218: 0,
  8220: 2,
  8222: 0,
  8224: 2,
  8225: 0,
  8226: 2,
  8311: 0,
  8312: 1,
  8313: 0,
  8314: 1,
  8315: 0,
  8316: 1,
  8317: 0,
  8318: 1,
  8319: 0,
  8320: 1,
  8321: 0,
  8322: 1,
  8323: 0,
  8324: 1,
  8325: 0,
  8326: 1,
  8327: 0,
  8328: 1,
  8329: 0,
  8330: 1,
  8331: 0,
  8332: 1,
  8333: 0,
  8334: 1,
  8335: 0,
  8336: 1,
  8337: 0,
  8338: 1,
  8339: 0,
  8340: 1,
  8341: 0,
  8342: 1,
  8343: 0,
  8344: 1,
  8345: 0,
  8346: 1,
  8347: 0,
  8348: 1,
  8352: 0,
  8353: 0,
  8379: 0,
  8380: 2,
  8381: 0,
  8382: 2,
  8383: 0,
  8384: 2,
  8385: 0,
  8387: 2,
  8391: 0,
  8395: 0,
  8433: 0,
  8441: 0,
  8455: 0,
  8456: 0,
  8531: 2,
  8682: 0,
  8686: 0,
  8687: 0,
  8692: 0,
  8693: 0,
  8826: 0,
  8903: 0,
  8950: 0,
  8951: 0,
  9039: 0,
  9040: 0,
  9141: 0,
  9149: 0,
  9150: 0,
  9191: 0,
  9221: 0,
  9222: 0,
  9249: 0,
  9250: 0,
  9252: 0,
  9254: 0,
  9265: 0,
  9284: 0,
  9285: 0,
  9300: 0,
  9354: 0,
  9367: 0,
  9373: 0,
  9377: 0,
  9387: 0,
  9391: 0,
  9456: 0,
  9473: 0,
  9498: 0,
  9674: 0,
  9678: 0,
  9680: 0,
  9709: 0,
  9712: 0,
  9713: 0,
  9716: 0,
  9741: 0,
  9748: 2,
  9749: 2,
  9761: 0,
  9766: 0,
  9793: 0,
  9794: 0,
  9869: 0,
  9874: 0,
  9875: 0,
  9880: 0,
  9895: 0,
  9943: 0,
  9945: 0,
  9947: 0,
  9967: 0,
  9972: 0,
  9977: 0,
  10160: 0,
  10183: 0,
  10188: 0,
  10194: 0,
  10199: 0,
  10207: 0,
  10212: 0,
  10217: 0,
  10222: 0,
  10227: 0,
  10235: 0,
  10240: 0,
  10250: 0,
  10254: 0,
  10270: 0,
  10275: 0,
  10280: 0,
  10306: 0,
  10329: 0,
  10471: 0,
  10477: 0,
  10481: 2,
  10516: 2,
  20002: 0,
  20042: 0,
  20050: 1,
  20499: 0,
  20538: 0,
  20539: 0,
  20790: 0,
  20791: 0,
  21291: 0,
  21292: 0,
  21500: 0,
  21817: 0,
  21818: 0,
  22032: 0,
  22033: 0,
  22091: 0,
  22092: 0,
  22239: 0,
  22240: 0,
  22332: 0,
  22337: 0,
  22338: 0,
  22391: 0,
  22392: 0,
  22639: 0,
  22700: 0,
  22739: 0,
  22770: 0,
  22780: 0,
  22832: 0,
  23090: 0,
  23095: 0,
  23239: 0,
  23240: 0,
  23433: 0,
  23700: 0,
  24047: 0,
  24048: 0,
  24100: 3,
  24200: 0,
  24305: 0,
  24306: 0,
  24382: 10,
  24383: 0,
  24500: 0,
  24547: 0,
  24548: 0,
  24571: 9,
  24600: 0,
  25e3: 0,
  25231: 0,
  25884: 0,
  25932: 0,
  26237: 0,
  26331: 0,
  26332: 0,
  26432: 0,
  26591: 0,
  26592: 0,
  26632: 0,
  26692: 0,
  27120: 0,
  27200: 0,
  27291: 6,
  27292: 6,
  27429: 0,
  27492: 0,
  27493: 0,
  27500: 0,
  28232: 0,
  28600: 0,
  28991: 0,
  28992: 0,
  29100: 0,
  29101: 0,
  29220: 0,
  29221: 0,
  29333: 0,
  29635: 0,
  29636: 0,
  29701: 0,
  29738: 0,
  29739: 0,
  29849: 0,
  29850: 0,
  29871: 8,
  29872: 7,
  29873: 0,
  29874: 0,
  30200: 5,
  30339: 0,
  30340: 0,
  30591: 0,
  30592: 0,
  30791: 0,
  30792: 0,
  30800: 0,
  31028: 0,
  31121: 0,
  31154: 0,
  31170: 0,
  31171: 0,
  31370: 0,
  31528: 0,
  31529: 0,
  31600: 0,
  31700: 0,
  31838: 0,
  31839: 0,
  31900: 0,
  31901: 0,
  32061: 0,
  32062: 0,
  32098: 0,
  32099: 2,
  32100: 0,
  32104: 0,
  32161: 0,
  32766: 0,
  53048: 0,
  53049: 0,
  54090: 0,
  54091: 0,
  65061: 2,
  65062: 2,
  65161: 0,
  65163: 0,
  102041: 2,
  102064: 11,
  102068: 15,
  102069: 16,
  102118: 2,
  102119: 1,
  102120: 2,
  102121: 2,
  102217: 2,
  102218: 0,
  102219: 2,
  102220: 2,
  102378: 1,
  102379: 1,
  102380: 0,
  102381: 1,
  102589: 2,
  102599: 2,
  102600: 2,
  102604: 2,
  102647: 0,
  102704: 2,
  102705: 2,
  102706: 0,
  102731: 0,
  102732: 0,
  102759: 1,
  102760: 1,
  102761: 2,
  102762: 0,
  102763: 2,
  102764: 0,
  102765: 0,
  102766: 2,
  102970: 1,
  102974: 2,
  102993: 0,
  102994: 0,
  102995: 2,
  102996: 2,
  103015: 0,
  103016: 2,
  103017: 0,
  103018: 2,
  103025: 0,
  103026: 0,
  103027: 2,
  103028: 2,
  103035: 0,
  103036: 0,
  103037: 2,
  103038: 2,
  103039: 0,
  103040: 0,
  103041: 2,
  103042: 2,
  103043: 0,
  103044: 0,
  103045: 2,
  103046: 2,
  103047: 0,
  103048: 0,
  103049: 2,
  103050: 2,
  103051: 0,
  103052: 2,
  103053: 0,
  103054: 2,
  103055: 0,
  103056: 2,
  103057: 0,
  103058: 0,
  103059: 2,
  103060: 2,
  103061: 0,
  103062: 0,
  103063: 2,
  103064: 2,
  103069: 2,
  103070: 0,
  103071: 0,
  103072: 2,
  103073: 2,
  103086: 0,
  103087: 0,
  103088: 2,
  103089: 2,
  103094: 1,
  103095: 0,
  103096: 2,
  103103: 0,
  103104: 2,
  103105: 0,
  103106: 2,
  103121: 0,
  103122: 2,
  103123: 0,
  103124: 0,
  103125: 1,
  103126: 1,
  103127: 0,
  103128: 0,
  103129: 2,
  103130: 2,
  103131: 0,
  103132: 0,
  103133: 2,
  103134: 2,
  103135: 0,
  103136: 0,
  103137: 1,
  103138: 1,
  103139: 0,
  103140: 2,
  103141: 0,
  103142: 2,
  103143: 0,
  103144: 2,
  103145: 0,
  103146: 1,
  103147: 0,
  103148: 0,
  103149: 2,
  103150: 2,
  103151: 0,
  103152: 2,
  103172: 0,
  103173: 2,
  103174: 0,
  103175: 0,
  103176: 2,
  103177: 2,
  103178: 0,
  103179: 0,
  103180: 2,
  103181: 2,
  103182: 0,
  103183: 0,
  103184: 2,
  103185: 2,
  103228: 0,
  103229: 0,
  103230: 2,
  103231: 2,
  103250: 0,
  103251: 2,
  103252: 0,
  103253: 2,
  103260: 0,
  103261: 0,
  103262: 2,
  103263: 2,
  103270: 0,
  103271: 0,
  103272: 2,
  103273: 2,
  103274: 0,
  103275: 0,
  103276: 2,
  103277: 2,
  103278: 0,
  103279: 0,
  103280: 2,
  103281: 2,
  103282: 0,
  103283: 0,
  103284: 2,
  103285: 2,
  103286: 0,
  103287: 2,
  103288: 0,
  103289: 2,
  103290: 0,
  103291: 2,
  103292: 0,
  103293: 0,
  103294: 2,
  103295: 2,
  103296: 0,
  103297: 0,
  103298: 2,
  103299: 2,
  103376: 2,
  103377: 0,
  103378: 0,
  103379: 2,
  103380: 2,
  103393: 0,
  103394: 0,
  103395: 2,
  103396: 2,
  103472: 0,
  103473: 1,
  103474: 0,
  103475: 2,
  103482: 0,
  103483: 2,
  103484: 0,
  103485: 2,
  103500: 0,
  103501: 2,
  103502: 0,
  103503: 0,
  103504: 1,
  103505: 1,
  103506: 0,
  103507: 0,
  103508: 2,
  103509: 2,
  103510: 0,
  103511: 0,
  103512: 2,
  103513: 2,
  103514: 0,
  103515: 2,
  103516: 0,
  103517: 2,
  103518: 0,
  103519: 2,
  103520: 0,
  103521: 1,
  103522: 0,
  103523: 0,
  103524: 2,
  103525: 2,
  103526: 0,
  103527: 2,
  103561: 2,
  103562: 2,
  103563: 0,
  103564: 0,
  103565: 2,
  103566: 2,
  103567: 0,
  103568: 0,
  103569: 2,
  103570: 2,
  103584: 0,
  103585: 2,
  103586: 0,
  103587: 2,
  103588: 1,
  103589: 0,
  103590: 2,
  103591: 1,
  103592: 0,
  103593: 2,
  103594: 1,
  103695: 2,
  103897: 1
};
for (o = 2e3; o <= 2045; o++) r[o] = 0;
for (o = 2056; o <= 2065; o++) r[o] = 0;
for (o = 2067; o <= 2135; o++) r[o] = 0;
for (o = 2137; o <= 2154; o++) r[o] = 0;
for (o = 2161; o <= 2170; o++) r[o] = 0;
for (o = 2172; o <= 2193; o++) r[o] = 0;
for (o = 2195; o <= 2198; o++) r[o] = 0;
for (o = 2200; o <= 2203; o++) r[o] = 0;
for (o = 2205; o <= 2217; o++) r[o] = 0;
for (o = 2222; o <= 2224; o++) r[o] = 1;
for (o = 2225; o <= 2250; o++) r[o] = 2;
for (o = 2251; o <= 2253; o++) r[o] = 1;
for (o = 2257; o <= 2264; o++) r[o] = 2;
for (o = 2274; o <= 2279; o++) r[o] = 2;
for (o = 2280; o <= 2282; o++) r[o] = 1;
for (o = 2283; o <= 2289; o++) r[o] = 2;
for (o = 2290; o <= 2292; o++) r[o] = 0;
for (o = 2308; o <= 2313; o++) r[o] = 0;
for (o = 2315; o <= 2491; o++) r[o] = 0;
for (o = 2494; o <= 2866; o++) r[o] = 0;
for (o = 2867; o <= 2869; o++) r[o] = 1;
for (o = 2870; o <= 2888; o++) r[o] = 2;
for (o = 2891; o <= 2895; o++) r[o] = 2;
for (o = 2896; o <= 2898; o++) r[o] = 1;
for (o = 2902; o <= 2908; o++) r[o] = 2;
for (o = 2915; o <= 2920; o++) r[o] = 2;
for (o = 2921; o <= 2923; o++) r[o] = 1;
for (o = 2924; o <= 2930; o++) r[o] = 2;
for (o = 2931; o <= 2962; o++) r[o] = 0;
for (o = 2964; o <= 2968; o++) r[o] = 2;
for (o = 2969; o <= 2973; o++) r[o] = 0;
for (o = 2975; o <= 2991; o++) r[o] = 0;
for (o = 2995; o <= 3051; o++) r[o] = 0;
for (o = 3054; o <= 3079; o++) r[o] = 0;
for (o = 3081; o <= 3088; o++) r[o] = 0;
for (o = 3092; o <= 3101; o++) r[o] = 0;
for (o = 3106; o <= 3138; o++) r[o] = 0;
for (o = 3146; o <= 3151; o++) r[o] = 0;
for (o = 3153; o <= 3166; o++) r[o] = 0;
for (o = 3168; o <= 3172; o++) r[o] = 0;
for (o = 3174; o <= 3203; o++) r[o] = 0;
for (o = 3294; o <= 3358; o++) r[o] = 0;
for (o = 3367; o <= 3403; o++) r[o] = 0;
for (o = 3408; o <= 3416; o++) r[o] = 0;
for (o = 3417; o <= 3438; o++) r[o] = 2;
for (o = 3441; o <= 3446; o++) r[o] = 2;
for (o = 3447; o <= 3450; o++) r[o] = 0;
for (o = 3451; o <= 3459; o++) r[o] = 2;
for (o = 3460; o <= 3478; o++) r[o] = 0;
for (o = 3554; o <= 3559; o++) r[o] = 0;
for (o = 3560; o <= 3570; o++) r[o] = 2;
for (o = 3571; o <= 3581; o++) r[o] = 0;
for (o = 3594; o <= 3597; o++) r[o] = 0;
for (o = 3601; o <= 3604; o++) r[o] = 0;
for (o = 3637; o <= 3639; o++) r[o] = 0;
for (o = 3665; o <= 3667; o++) r[o] = 0;
for (o = 3693; o <= 3695; o++) r[o] = 0;
for (o = 3701; o <= 3727; o++) r[o] = 0;
for (o = 3728; o <= 3739; o++) r[o] = 2;
for (o = 3740; o <= 3751; o++) r[o] = 0;
for (o = 3753; o <= 3760; o++) r[o] = 2;
for (o = 3761; o <= 3773; o++) r[o] = 0;
for (o = 3775; o <= 3777; o++) r[o] = 0;
for (o = 3779; o <= 3781; o++) r[o] = 0;
for (o = 3783; o <= 3785; o++) r[o] = 0;
for (o = 3788; o <= 3791; o++) r[o] = 0;
for (o = 3797; o <= 3802; o++) r[o] = 0;
for (o = 3814; o <= 3816; o++) r[o] = 0;
for (o = 3825; o <= 3829; o++) r[o] = 0;
for (o = 3832; o <= 3841; o++) r[o] = 0;
for (o = 3844; o <= 3852; o++) r[o] = 0;
for (o = 3873; o <= 3885; o++) r[o] = 0;
for (o = 3890; o <= 3893; o++) r[o] = 0;
for (o = 3907; o <= 3912; o++) r[o] = 0;
for (o = 3942; o <= 3950; o++) r[o] = 0;
for (o = 3968; o <= 3970; o++) r[o] = 0;
for (o = 3973; o <= 3976; o++) r[o] = 0;
for (o = 3986; o <= 3989; o++) r[o] = 0;
for (o = 3994; o <= 3997; o++) r[o] = 0;
for (o = 4048; o <= 4051; o++) r[o] = 0;
for (o = 4056; o <= 4063; o++) r[o] = 0;
for (o = 4093; o <= 4096; o++) r[o] = 0;
for (o = 4390; o <= 4398; o++) r[o] = 0;
for (o = 4399; o <= 4413; o++) r[o] = 2;
for (o = 4418; o <= 4433; o++) r[o] = 2;
for (o = 4455; o <= 4457; o++) r[o] = 2;
for (o = 4484; o <= 4489; o++) r[o] = 0;
for (o = 4491; o <= 4554; o++) r[o] = 0;
for (o = 4568; o <= 4589; o++) r[o] = 0;
for (o = 4652; o <= 4656; o++) r[o] = 0;
for (o = 4766; o <= 4800; o++) r[o] = 0;
for (o = 5014; o <= 5016; o++) r[o] = 0;
for (o = 5069; o <= 5072; o++) r[o] = 0;
for (o = 5105; o <= 5130; o++) r[o] = 0;
for (o = 5167; o <= 5188; o++) r[o] = 0;
for (o = 5253; o <= 5259; o++) r[o] = 0;
for (o = 5269; o <= 5275; o++) r[o] = 0;
for (o = 5292; o <= 5311; o++) r[o] = 0;
for (o = 5329; o <= 5331; o++) r[o] = 0;
for (o = 5343; o <= 5349; o++) r[o] = 0;
for (o = 5355; o <= 5357; o++) r[o] = 0;
for (o = 5387; o <= 5389; o++) r[o] = 0;
for (o = 5459; o <= 5463; o++) r[o] = 0;
for (o = 5479; o <= 5482; o++) r[o] = 0;
for (o = 5518; o <= 5520; o++) r[o] = 0;
for (o = 5530; o <= 5539; o++) r[o] = 0;
for (o = 5550; o <= 5552; o++) r[o] = 0;
for (o = 5562; o <= 5583; o++) r[o] = 0;
for (o = 5623; o <= 5625; o++) r[o] = 2;
for (o = 5631; o <= 5639; o++) r[o] = 0;
for (o = 5649; o <= 5653; o++) r[o] = 0;
for (o = 5663; o <= 5680; o++) r[o] = 0;
for (o = 5682; o <= 5685; o++) r[o] = 0;
for (o = 5875; o <= 5877; o++) r[o] = 0;
for (o = 5896; o <= 5899; o++) r[o] = 0;
for (o = 5921; o <= 5940; o++) r[o] = 0;
for (o = 6050; o <= 6125; o++) r[o] = 0;
for (o = 6244; o <= 6275; o++) r[o] = 0;
for (o = 6328; o <= 6348; o++) r[o] = 0;
for (o = 6350; o <= 6356; o++) r[o] = 0;
for (o = 6366; o <= 6372; o++) r[o] = 0;
for (o = 6381; o <= 6387; o++) r[o] = 0;
for (o = 6393; o <= 6404; o++) r[o] = 0;
for (o = 6480; o <= 6483; o++) r[o] = 0;
for (o = 6511; o <= 6514; o++) r[o] = 0;
for (o = 6579; o <= 6581; o++) r[o] = 0;
for (o = 6619; o <= 6624; o++) r[o] = 0;
for (o = 6625; o <= 6627; o++) r[o] = 2;
for (o = 6628; o <= 6632; o++) r[o] = 0;
for (o = 6634; o <= 6637; o++) r[o] = 0;
for (o = 6669; o <= 6692; o++) r[o] = 0;
for (o = 6707; o <= 6709; o++) r[o] = 0;
for (o = 6720; o <= 6723; o++) r[o] = 0;
for (o = 6732; o <= 6738; o++) r[o] = 0;
for (o = 6931; o <= 6933; o++) r[o] = 0;
for (o = 6956; o <= 6959; o++) r[o] = 0;
for (o = 7005; o <= 7007; o++) r[o] = 0;
for (o = 7057; o <= 7070; o++) r[o] = 2;
for (o = 7074; o <= 7082; o++) r[o] = 0;
for (o = 7109; o <= 7118; o++) r[o] = 0;
for (o = 7119; o <= 7127; o++) r[o] = 1;
for (o = 7374; o <= 7376; o++) r[o] = 0;
for (o = 7528; o <= 7586; o++) r[o] = 0;
for (o = 7587; o <= 7645; o++) r[o] = 2;
for (o = 7692; o <= 7696; o++) r[o] = 0;
for (o = 7755; o <= 7787; o++) r[o] = 0;
for (o = 7791; o <= 7795; o++) r[o] = 0;
for (o = 7799; o <= 7801; o++) r[o] = 0;
for (o = 7803; o <= 7805; o++) r[o] = 0;
for (o = 7825; o <= 7831; o++) r[o] = 0;
for (o = 7845; o <= 7859; o++) r[o] = 0;
for (o = 8013; o <= 8032; o++) r[o] = 0;
for (o = 8065; o <= 8068; o++) r[o] = 1;
for (o = 8518; o <= 8529; o++) r[o] = 2;
for (o = 8533; o <= 8536; o++) r[o] = 2;
for (o = 8538; o <= 8540; o++) r[o] = 2;
for (o = 8677; o <= 8679; o++) r[o] = 0;
for (o = 8836; o <= 8840; o++) r[o] = 0;
for (o = 8857; o <= 8859; o++) r[o] = 0;
for (o = 8908; o <= 8910; o++) r[o] = 0;
for (o = 9154; o <= 9159; o++) r[o] = 0;
for (o = 9205; o <= 9218; o++) r[o] = 0;
for (o = 9271; o <= 9273; o++) r[o] = 0;
for (o = 9295; o <= 9297; o++) r[o] = 0;
for (o = 9356; o <= 9360; o++) r[o] = 0;
for (o = 9404; o <= 9407; o++) r[o] = 0;
for (o = 9476; o <= 9482; o++) r[o] = 0;
for (o = 9487; o <= 9494; o++) r[o] = 0;
for (o = 9697; o <= 9699; o++) r[o] = 0;
for (o = 9821; o <= 9865; o++) r[o] = 0;
for (o = 10285; o <= 10291; o++) r[o] = 0;
for (o = 10314; o <= 10317; o++) r[o] = 0;
for (o = 10448; o <= 10465; o++) r[o] = 0;
for (o = 10731; o <= 10733; o++) r[o] = 0;
for (o = 11114; o <= 11118; o++) r[o] = 0;
for (o = 20004; o <= 20032; o++) r[o] = 0;
for (o = 20047; o <= 20049; o++) r[o] = 0;
for (o = 20062; o <= 20092; o++) r[o] = 0;
for (o = 20135; o <= 20138; o++) r[o] = 0;
for (o = 20248; o <= 20258; o++) r[o] = 0;
for (o = 20348; o <= 20358; o++) r[o] = 0;
for (o = 20436; o <= 20440; o++) r[o] = 0;
for (o = 20822; o <= 20824; o++) r[o] = 0;
for (o = 20904; o <= 20932; o++) r[o] = 0;
for (o = 20934; o <= 20936; o++) r[o] = 0;
for (o = 21004; o <= 21032; o++) r[o] = 0;
for (o = 21035; o <= 21037; o++) r[o] = 0;
for (o = 21095; o <= 21097; o++) r[o] = 0;
for (o = 21148; o <= 21150; o++) r[o] = 0;
for (o = 21207; o <= 21264; o++) r[o] = 0;
for (o = 21307; o <= 21364; o++) r[o] = 0;
for (o = 21413; o <= 21423; o++) r[o] = 0;
for (o = 21453; o <= 21463; o++) r[o] = 0;
for (o = 21473; o <= 21483; o++) r[o] = 0;
for (o = 21780; o <= 21782; o++) r[o] = 0;
for (o = 21891; o <= 21894; o++) r[o] = 0;
for (o = 21896; o <= 21899; o++) r[o] = 0;
for (o = 22171; o <= 22177; o++) r[o] = 0;
for (o = 22181; o <= 22187; o++) r[o] = 0;
for (o = 22191; o <= 22197; o++) r[o] = 0;
for (o = 22207; o <= 22222; o++) r[o] = 0;
for (o = 22229; o <= 22232; o++) r[o] = 0;
for (o = 22234; o <= 22236; o++) r[o] = 0;
for (o = 22243; o <= 22250; o++) r[o] = 0;
for (o = 22262; o <= 22265; o++) r[o] = 0;
for (o = 22307; o <= 22322; o++) r[o] = 0;
for (o = 22348; o <= 22357; o++) r[o] = 0;
for (o = 22407; o <= 22422; o++) r[o] = 0;
for (o = 22462; o <= 22465; o++) r[o] = 0;
for (o = 22521; o <= 22525; o++) r[o] = 0;
for (o = 22607; o <= 22622; o++) r[o] = 0;
for (o = 22641; o <= 22646; o++) r[o] = 0;
for (o = 22648; o <= 22657; o++) r[o] = 0;
for (o = 22707; o <= 22722; o++) r[o] = 0;
for (o = 22762; o <= 22765; o++) r[o] = 0;
for (o = 22807; o <= 22822; o++) r[o] = 0;
for (o = 22991; o <= 22994; o++) r[o] = 0;
for (o = 23028; o <= 23038; o++) r[o] = 0;
for (o = 23301; o <= 23333; o++) r[o] = 2;
for (o = 23830; o <= 23853; o++) r[o] = 0;
for (o = 23866; o <= 23872; o++) r[o] = 0;
for (o = 23877; o <= 23884; o++) r[o] = 0;
for (o = 23886; o <= 23894; o++) r[o] = 0;
for (o = 23946; o <= 23948; o++) r[o] = 0;
for (o = 24311; o <= 24313; o++) r[o] = 0;
for (o = 24342; o <= 24347; o++) r[o] = 0;
for (o = 24370; o <= 24374; o++) r[o] = 10;
for (o = 24375; o <= 24381; o++) r[o] = 0;
for (o = 24718; o <= 24721; o++) r[o] = 0;
for (o = 24817; o <= 24821; o++) r[o] = 0;
for (o = 24877; o <= 24882; o++) r[o] = 0;
for (o = 24891; o <= 24893; o++) r[o] = 0;
for (o = 25391; o <= 25395; o++) r[o] = 0;
for (o = 25828; o <= 25838; o++) r[o] = 0;
for (o = 26191; o <= 26195; o++) r[o] = 0;
for (o = 26391; o <= 26393; o++) r[o] = 0;
for (o = 26701; o <= 26722; o++) r[o] = 0;
for (o = 26729; o <= 26799; o++) r[o] = 2;
for (o = 26801; o <= 26803; o++) r[o] = 2;
for (o = 26811; o <= 26813; o++) r[o] = 2;
for (o = 26847; o <= 26870; o++) r[o] = 2;
for (o = 26891; o <= 26899; o++) r[o] = 0;
for (o = 26901; o <= 26923; o++) r[o] = 0;
for (o = 26929; o <= 26946; o++) r[o] = 0;
for (o = 26948; o <= 26998; o++) r[o] = 0;
for (o = 27037; o <= 27040; o++) r[o] = 0;
for (o = 27205; o <= 27232; o++) r[o] = 0;
for (o = 27258; o <= 27260; o++) r[o] = 0;
for (o = 27391; o <= 27398; o++) r[o] = 0;
for (o = 27561; o <= 27564; o++) r[o] = 0;
for (o = 27571; o <= 27574; o++) r[o] = 0;
for (o = 27581; o <= 27584; o++) r[o] = 0;
for (o = 27591; o <= 27594; o++) r[o] = 0;
for (o = 27700; o <= 27707; o++) r[o] = 0;
for (o = 28191; o <= 28193; o++) r[o] = 0;
for (o = 28348; o <= 28358; o++) r[o] = 0;
for (o = 28402; o <= 28432; o++) r[o] = 0;
for (o = 28462; o <= 28492; o++) r[o] = 0;
for (o = 29118; o <= 29122; o++) r[o] = 0;
for (o = 29168; o <= 29172; o++) r[o] = 0;
for (o = 29177; o <= 29185; o++) r[o] = 0;
for (o = 29187; o <= 29195; o++) r[o] = 0;
for (o = 29900; o <= 29903; o++) r[o] = 0;
for (o = 30161; o <= 30179; o++) r[o] = 0;
for (o = 30491; o <= 30494; o++) r[o] = 0;
for (o = 30729; o <= 30732; o++) r[o] = 0;
for (o = 31251; o <= 31259; o++) r[o] = 0;
for (o = 31265; o <= 31268; o++) r[o] = 0;
for (o = 31275; o <= 31279; o++) r[o] = 0;
for (o = 31281; o <= 31297; o++) r[o] = 0;
for (o = 31461; o <= 31469; o++) r[o] = 0;
for (o = 31491; o <= 31495; o++) r[o] = 0;
for (o = 31917; o <= 31922; o++) r[o] = 0;
for (o = 31965; o <= 32e3; o++) r[o] = 0;
for (o = 32001; o <= 32003; o++) r[o] = 2;
for (o = 32005; o <= 32031; o++) r[o] = 2;
for (o = 32033; o <= 32060; o++) r[o] = 2;
for (o = 32064; o <= 32067; o++) r[o] = 2;
for (o = 32074; o <= 32077; o++) r[o] = 2;
for (o = 32081; o <= 32086; o++) r[o] = 0;
for (o = 32107; o <= 32130; o++) r[o] = 0;
for (o = 32133; o <= 32159; o++) r[o] = 0;
for (o = 32164; o <= 32167; o++) r[o] = 2;
for (o = 32180; o <= 32199; o++) r[o] = 0;
for (o = 32201; o <= 32260; o++) r[o] = 0;
for (o = 32301; o <= 32360; o++) r[o] = 0;
for (o = 32401; o <= 32460; o++) r[o] = 0;
for (o = 32501; o <= 32560; o++) r[o] = 0;
for (o = 32601; o <= 32662; o++) r[o] = 0;
for (o = 32664; o <= 32667; o++) r[o] = 2;
for (o = 32701; o <= 32761; o++) r[o] = 0;
for (o = 53001; o <= 53004; o++) r[o] = 0;
for (o = 53008; o <= 53019; o++) r[o] = 0;
for (o = 53021; o <= 53032; o++) r[o] = 0;
for (o = 53034; o <= 53037; o++) r[o] = 0;
for (o = 53042; o <= 53046; o++) r[o] = 0;
for (o = 53074; o <= 53080; o++) r[o] = 0;
for (o = 54001; o <= 54004; o++) r[o] = 0;
for (o = 54008; o <= 54019; o++) r[o] = 0;
for (o = 54021; o <= 54032; o++) r[o] = 0;
for (o = 54034; o <= 54037; o++) r[o] = 0;
for (o = 54042; o <= 54046; o++) r[o] = 0;
for (o = 54048; o <= 54053; o++) r[o] = 0;
for (o = 54074; o <= 54080; o++) r[o] = 0;
for (o = 54098; o <= 54101; o++) r[o] = 0;
for (o = 102001; o <= 102040; o++) r[o] = 0;
for (o = 102042; o <= 102063; o++) r[o] = 0;
for (o = 102065; o <= 102067; o++) r[o] = 0;
for (o = 102070; o <= 102117; o++) r[o] = 0;
for (o = 102122; o <= 102216; o++) r[o] = 0;
for (o = 102221; o <= 102377; o++) r[o] = 0;
for (o = 102382; o <= 102388; o++) r[o] = 0;
for (o = 102389; o <= 102398; o++) r[o] = 2;
for (o = 102399; o <= 102444; o++) r[o] = 0;
for (o = 102445; o <= 102447; o++) r[o] = 2;
for (o = 102448; o <= 102458; o++) r[o] = 0;
for (o = 102459; o <= 102468; o++) r[o] = 2;
for (o = 102469; o <= 102499; o++) r[o] = 0;
for (o = 102500; o <= 102519; o++) r[o] = 1;
for (o = 102520; o <= 102524; o++) r[o] = 0;
for (o = 102525; o <= 102529; o++) r[o] = 2;
for (o = 102530; o <= 102588; o++) r[o] = 0;
for (o = 102590; o <= 102598; o++) r[o] = 0;
for (o = 102601; o <= 102603; o++) r[o] = 0;
for (o = 102605; o <= 102628; o++) r[o] = 0;
for (o = 102629; o <= 102646; o++) r[o] = 2;
for (o = 102648; o <= 102700; o++) r[o] = 2;
for (o = 102701; o <= 102703; o++) r[o] = 0;
for (o = 102707; o <= 102730; o++) r[o] = 2;
for (o = 102733; o <= 102758; o++) r[o] = 2;
for (o = 102767; o <= 102900; o++) r[o] = 0;
for (o = 102901; o <= 102933; o++) r[o] = 2;
for (o = 102934; o <= 102950; o++) r[o] = 13;
for (o = 102951; o <= 102963; o++) r[o] = 0;
for (o = 102965; o <= 102969; o++) r[o] = 0;
for (o = 102971; o <= 102973; o++) r[o] = 0;
for (o = 102975; o <= 102989; o++) r[o] = 0;
for (o = 102990; o <= 102992; o++) r[o] = 1;
for (o = 102997; o <= 103002; o++) r[o] = 0;
for (o = 103003; o <= 103008; o++) r[o] = 2;
for (o = 103009; o <= 103011; o++) r[o] = 0;
for (o = 103012; o <= 103014; o++) r[o] = 2;
for (o = 103019; o <= 103021; o++) r[o] = 0;
for (o = 103022; o <= 103024; o++) r[o] = 2;
for (o = 103029; o <= 103031; o++) r[o] = 0;
for (o = 103032; o <= 103034; o++) r[o] = 2;
for (o = 103065; o <= 103068; o++) r[o] = 0;
for (o = 103074; o <= 103076; o++) r[o] = 0;
for (o = 103077; o <= 103079; o++) r[o] = 1;
for (o = 103080; o <= 103082; o++) r[o] = 0;
for (o = 103083; o <= 103085; o++) r[o] = 2;
for (o = 103090; o <= 103093; o++) r[o] = 0;
for (o = 103097; o <= 103099; o++) r[o] = 0;
for (o = 103100; o <= 103102; o++) r[o] = 2;
for (o = 103107; o <= 103109; o++) r[o] = 0;
for (o = 103110; o <= 103112; o++) r[o] = 2;
for (o = 103113; o <= 103116; o++) r[o] = 0;
for (o = 103117; o <= 103120; o++) r[o] = 2;
for (o = 103153; o <= 103157; o++) r[o] = 0;
for (o = 103158; o <= 103162; o++) r[o] = 2;
for (o = 103163; o <= 103165; o++) r[o] = 0;
for (o = 103166; o <= 103168; o++) r[o] = 1;
for (o = 103169; o <= 103171; o++) r[o] = 2;
for (o = 103186; o <= 103188; o++) r[o] = 0;
for (o = 103189; o <= 103191; o++) r[o] = 2;
for (o = 103192; o <= 103195; o++) r[o] = 0;
for (o = 103196; o <= 103199; o++) r[o] = 2;
for (o = 103200; o <= 103224; o++) r[o] = 0;
for (o = 103225; o <= 103227; o++) r[o] = 1;
for (o = 103232; o <= 103237; o++) r[o] = 0;
for (o = 103238; o <= 103243; o++) r[o] = 2;
for (o = 103244; o <= 103246; o++) r[o] = 0;
for (o = 103247; o <= 103249; o++) r[o] = 2;
for (o = 103254; o <= 103256; o++) r[o] = 0;
for (o = 103257; o <= 103259; o++) r[o] = 2;
for (o = 103264; o <= 103266; o++) r[o] = 0;
for (o = 103267; o <= 103269; o++) r[o] = 2;
for (o = 103300; o <= 103375; o++) r[o] = 0;
for (o = 103381; o <= 103383; o++) r[o] = 0;
for (o = 103384; o <= 103386; o++) r[o] = 1;
for (o = 103387; o <= 103389; o++) r[o] = 0;
for (o = 103390; o <= 103392; o++) r[o] = 2;
for (o = 103397; o <= 103399; o++) r[o] = 0;
for (o = 103400; o <= 103471; o++) r[o] = 2;
for (o = 103476; o <= 103478; o++) r[o] = 0;
for (o = 103479; o <= 103481; o++) r[o] = 2;
for (o = 103486; o <= 103488; o++) r[o] = 0;
for (o = 103489; o <= 103491; o++) r[o] = 2;
for (o = 103492; o <= 103495; o++) r[o] = 0;
for (o = 103496; o <= 103499; o++) r[o] = 2;
for (o = 103528; o <= 103543; o++) r[o] = 0;
for (o = 103544; o <= 103548; o++) r[o] = 2;
for (o = 103549; o <= 103551; o++) r[o] = 0;
for (o = 103552; o <= 103554; o++) r[o] = 1;
for (o = 103555; o <= 103557; o++) r[o] = 2;
for (o = 103558; o <= 103560; o++) r[o] = 0;
for (o = 103571; o <= 103573; o++) r[o] = 0;
for (o = 103574; o <= 103576; o++) r[o] = 2;
for (o = 103577; o <= 103580; o++) r[o] = 0;
for (o = 103581; o <= 103583; o++) r[o] = 2;
for (o = 103595; o <= 103694; o++) r[o] = 0;
for (o = 103696; o <= 103699; o++) r[o] = 0;
for (o = 103700; o <= 103793; o++) r[o] = 2;
for (o = 103794; o <= 103890; o++) r[o] = 0;
for (o = 103891; o <= 103896; o++) r[o] = 2;
for (o = 103900; o <= 103971; o++) r[o] = 2;
for (o = 103972; o <= 103977; o++) r[o] = 0;
for (o = 112e3; o <= 112101; o++) r[o] = 0;
export { r as default };