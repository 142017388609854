/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.30/esri/copyright.txt for details.
*/
import { replace as e } from "../../core/string.js";
import { i as r, l as n, P as i, a as t } from "../../chunks/pe.js";
import { SupportedGCSWkids as _ } from "./SupportedGCSWkids.js";
import a from "./WKIDUnitConversion.js";
const l = [102100, 102113, 3857, 3785],
  E = [104905, 104971],
  M = [4326, ...l, ...E],
  S = 'PROJCS["WGS_1984_Web_Mercator_Auxiliary_Sphere",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Mercator_Auxiliary_Sphere"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",{Central_Meridian}],PARAMETER["Standard_Parallel_1",0.0],PARAMETER["Auxiliary_Sphere_Type",0.0],UNIT["Meter",1.0]]',
  u = [-20037508.342788905, 20037508.342788905],
  R = [-20037508.342787, 20037508.342787],
  o = {
    102113: {
      wkTemplate: 'PROJCS["WGS_1984_Web_Mercator",GEOGCS["GCS_WGS_1984_Major_Auxiliary_Sphere",DATUM["D_WGS_1984_Major_Auxiliary_Sphere",SPHEROID["WGS_1984_Major_Auxiliary_Sphere",6378137.0,0.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Mercator"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",{Central_Meridian}],PARAMETER["Standard_Parallel_1",0.0],UNIT["Meter",1.0]]',
      valid: u,
      origin: R,
      dx: 1e-5
    },
    102100: {
      wkTemplate: S,
      valid: u,
      origin: R,
      dx: 1e-5
    },
    3785: {
      wkTemplate: 'PROJCS["WGS_1984_Web_Mercator",GEOGCS["GCS_WGS_1984_Major_Auxiliary_Sphere",DATUM["D_WGS_1984_Major_Auxiliary_Sphere",SPHEROID["WGS_1984_Major_Auxiliary_Sphere",6378137.0,0.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Mercator"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",{Central_Meridian}],PARAMETER["Standard_Parallel_1",0.0],UNIT["Meter",1.0]]',
      valid: u,
      origin: R,
      dx: 1e-5
    },
    3857: {
      wkTemplate: S,
      valid: u,
      origin: R,
      dx: 1e-5
    },
    4326: {
      wkTemplate: 'GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",{Central_Meridian}],UNIT["Degree",0.0174532925199433]]',
      altTemplate: 'PROJCS["WGS_1984_Plate_Carree",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Plate_Carree"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",{Central_Meridian}],UNIT["Degrees",111319.491]]',
      valid: [-180, 180],
      origin: [-180, 90],
      dx: 1e-5
    },
    104971: {
      wkTemplate: 'GEOGCS["Mars_2000_(Sphere)",DATUM["Mars_2000_(Sphere)",SPHEROID["Mars_2000_(Sphere)",3396190.0,0.0]],PRIMEM["Reference_Meridian",0.0],UNIT["Degree",0.0174532925199433]]',
      valid: [-180, 180],
      origin: [-180, 90],
      dx: 1e-5
    },
    104905: {
      wkTemplate: 'GEOGCS["GCS_Mars_2000",DATUM["D_Mars_2000",SPHEROID["Mars_2000_IAU_IAG",3396190.0,169.8944472236118]],PRIMEM["Reference_Meridian",0.0],UNIT["Degree",0.0174532925199433]]',
      valid: [-180, 180],
      origin: [-180, 90],
      dx: 1e-5
    }
  };
function G(e, r) {
  if (e === r) return !0;
  if (null == e || null == r) return !1;
  const n = e.wkid,
    i = r.wkid;
  if (null != n || null != i) {
    if (n === i) return !0;
    if (w(e) && w(r)) return !0;
    const t = e.latestWkid;
    if (null != t && i === t) return !0;
    const _ = r.latestWkid;
    return null != _ && n === _;
  }
  let t, _;
  return e.wkt2 && r.wkt2 ? (t = e.wkt2, _ = r.wkt2) : e.wkt && r.wkt && (t = e.wkt, _ = r.wkt), !(!t || !_) && t.toUpperCase() === _.toUpperCase();
}
function s(e) {
  const r = e?.wkid;
  return null != r && r >= 2e3 && o[r] || null;
}
async function T(e, _) {
  if (1 === _ && !c(e) || 2 === _ && c(e)) return e;
  r() || (await n());
  const a = i.fromString(t.PE_TYPE_NONE, e);
  return a?.toString(1 === _ ? t.PE_STR_FMT_WKT : t.PE_STR_FMT_WKT2) ?? null;
}
function d(e) {
  if (!e) return;
  const {
    wkid: r,
    vcsWkid: n
  } = e;
  if (r) return n ? JSON.stringify({
    wkid: r,
    vcsWkid: n
  }) : String(r);
  const i = e.wkt || e.wkt2;
  return JSON.stringify(i ? n ? {
    wkt: i,
    vcsWkid: n
  } : {
    wkt: i
  } : {});
}
function A(e) {
  if (!N(e)) return !1;
  const {
    wkid: r
  } = e;
  if (r) return null == a[r];
  const n = e.wkt2 || e.wkt;
  return !!n && /^\s*(?:GEOGCS|GEOGCRS|GEOGRAPHICCRS)/i.test(n);
}
function P(e) {
  return !(f(e) || p(e));
}
function C(e) {
  return 4326 === e?.wkid;
}
function c(e) {
  return /^\s*(?:GEOD|GEODETIC|GEOG|GEOGRAPHIC|PROJ|PROJECTED|VERT|VERTICAL|ENG|ENGINEERING|PARAMETRIC|TIME|DERIVEDPROJ|COMPOUND|BOUND)CRS/i.test(e);
}
function k(e) {
  return e?.wkid === _.CGCS2000;
}
function w(e) {
  const r = e?.wkid;
  return null != r && r >= 2e3 && l.includes(r);
}
function O(e) {
  return 32662 === e?.wkid;
}
function I(e) {
  return e === _.GCSMARS2000 || e === _.GCSMARS2000_SPHERE;
}
function f(e) {
  return I(e?.wkid);
}
function D(e) {
  return e === _.GCSMOON2000;
}
function p(e) {
  return D(e?.wkid);
}
function W(e) {
  const r = e?.wkid;
  return null != r && r >= 2e3 && M.includes(r);
}
function N(e) {
  if (null == e) return !1;
  const r = e.wkid;
  return null != r && r >= 2e3 || null != e.wkt2 || null != e.wkt;
}
const g = {
    wkid: 4326,
    wkt: e(o[4326].wkTemplate, {
      Central_Meridian: "0.0"
    })
  },
  U = {
    wkid: 102100,
    latestWkid: 3857
  },
  h = {
    wkid: 32662
  };
export { G as equals, s as getInfo, T as getWKT, k as isCGCS2000, P as isEarth, A as isGeographic, f as isMars, p as isMoon, O as isPlateCarree, N as isValid, C as isWGS84, I as isWKIDFromMars, D as isWKIDFromMoon, c as isWKT2, w as isWebMercator, W as isWrappable, h as plateCarree, d as srToRESTValue, U as webMercator, R as webMercatorOrigin, g as wgs84 };